@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css?family=Barlow|Tomorrow:400,700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: Tomorrow, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-datepicker__month-container {
}

.MainContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.internal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
