.mapa {
  display: flex;
  justify-content: center;
  input {
    width: 90vw;
    &:focus {
      outline: none;
    }
  }
}

.suggestions-wrapper {
  width: 300px;
}

.suggestions-wrapper {
  width: 300px !important;
}

@media screen and (min-width: 640px) {
  .mapboxgl-ctrl-geocoder {
    width: 300px !important;
  }
}
.mapboxgl-marker {
  display: none;
}

.mapboxgl-canvas {
  display: none;
}

.mapboxgl-ctrl-attrib-inner {
  text-align: center;
  display: none;
  margin: 0 auto;
}
.mapboxgl-ctrl-attrib-button {
  display: none;
}
